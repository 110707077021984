import { T } from "@/locales";

export default [
  {
    key: "report",
    name: T("报表管理"),
    icon: "bar-chart",
    submenus: [
      { key: "warehouse_inventory", name: T("库存报表") },
      { key: "stock_in_report", name: T("入库报表") },
      { key: "stock_out_report", name: T("出库报表") },
    ],
  },
  {
    key: "order",
    name: T("订单管理"),
    icon: "file",
    submenus: [
      { key: "stock_in_order_list", name: T("入库订单") },
      { key: "stock_out_order_list", name: T("出库订单") },
    ],
  },
];
